import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/jasperkamphuis/Work/gatsby-theme-recipes-master/packages/gatsby-theme-recipes/src/components/MarkdownPagesLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Ontvang Updates`}</h1>
    <p>{`Mis geen enkel heerlijk recept of restauranttip! Schrijf je in voor onze nieuwsbrief en blijf op de hoogte van alle nieuwe smaakvolle toevoegingen en aanbevelingen.`}</p>
    <h2>{`Wat kun je verwachten?`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Nieuwe Recepten:`}</strong>{` Ontvang als eerste de nieuwste toevoegingen aan onze culinaire verzameling.`}</li>
      <li parentName="ul"><strong parentName="li">{`Restaurant Aanbevelingen:`}</strong>{` Ontdek de beste eetgelegenheden die wij aanbevelen voor een geweldige eetervaring.`}</li>
      <li parentName="ul"><strong parentName="li">{`Speciale Inhoud:`}</strong>{` Soms delen we exclusieve content, zoals kooktips, interviews met chefs, en meer!`}</li>
    </ul>
    <h2>{`Aanmelden`}</h2>
    <p>{`Vul hieronder je e-mailadres in en ontvang maandelijks updates over alles wat met lekker eten te maken heeft!`}</p>
    <form action="https://formspree.io/f/mrgnbepn" method="post" style={{
      "width": "100%",
      "maxWidth": "400px",
      "margin": "0"
    }}>
  <label style={{
        "display": "block",
        "marginBottom": "10px"
      }}>
    <span style={{
          "display": "block",
          "marginBottom": "5px"
        }}>Voornaam:</span>
    <input type="text" name="FNAME" required style={{
          "width": "100%",
          "padding": "8px",
          "boxSizing": "border-box"
        }} />
  </label>
  <label style={{
        "display": "block",
        "marginBottom": "10px"
      }}>
    <span style={{
          "display": "block",
          "marginBottom": "5px"
        }}>Email:</span>
    <input type="email" name="EMAIL" required style={{
          "width": "100%",
          "padding": "8px",
          "boxSizing": "border-box"
        }} />
  </label>
  <button type="submit" style={{
        "backgroundColor": "#d61c04",
        "color": "#fff",
        "padding": "10px",
        "border": "none",
        "cursor": "pointer",
        "width": "100%"
      }}>Inschrijven</button>
    </form>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      